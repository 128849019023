<template>
    <section>
        <div class="row mx-0">
            <div class="menu-prinicipal br-t-12">
                <div class="row mx-0 px-2 py-2 align-items-center" style="height:48px;">
                    <div class="col pl-0 my-auto">
                        <el-input v-model="buscar" placeholder="Buscar promoción" size="small" class="br-20" />
                    </div>
                    <el-tooltip v-if="$can('botones_promociones_gestionar')" class="item" effect="light" content="Crear promoción" placement="bottom">
                        <div class="btn-agregar-promocion cr-pointer d-middle-center br-8" @click="crearPromocion">
                            <i class="icon-plus f-20 text-white" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col border-top px-0 overflow-auto custom-scroll" style="width:400px;height:calc(100vh - 139px);">
                    <content-loader v-if="loading" />
                    <div
                    v-for="(menu, idx) in buscadorPromociones" :key="idx"
                    class="row mx-0 d-middle border-bottom cr-pointer"
                    style="height:68px;"
                    @click="activarCar(menu.id)"
                    >
                        <div :class="`mx-2 px-1 h-80 br-5 ${id_promocion==menu.id?'bg-general':''}`" />
                        <div class="col tres-puntos f-15 ">
                            <div class="row mx-0 ">
                                <p :class="`tres-puntos f-500 ${id_promocion==menu.id ? 'text-general ' : 'text-general2'}`">{{ menu.nombre }}</p>
                            </div>
                            <div class="row mx-0">
                                <p :class="`tres-puntos ${id_promocion==menu.id ? 'text-general' : 'text-general2'}`">
                                    Tipo:
                                    {{ menu.tiposhow }}
                                </p>
                            </div>
                        </div>
                        <el-tooltip class="item" effect="light" placement="bottom">
                            <div slot="content" class="" style="max-width:150px;">
                                Leecheros que pueden usar esta promoción
                            </div>
                            <div class="br-20 mx-2 my-auto bg-general3 d-middle text-white justify-content-between" style="min-width:57px;">
                                <i class="icon-warehouse mx-1  f-18" />
                                <span class="pr-2">{{ menu.cant_cedis }}</span>
                            </div>
                        </el-tooltip>
                        <i :class="`icon-angle-right f-18 ${id_promocion==menu.id?'text-general':''}`" />
                    </div>

                    <div v-if="promociones.length <= 0" class="row h-100 mx-0 align-items-center justify-content-center">
                        <div class="text-center f-17 f-600" style="width:225px;">
                            <p>Vamos a crear la primera promoción para los Cedis</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 col br-t-12 menu-prinicipal ml-3" style="width: calc(100vw - 560px);">
                <detalle />
            </div>
        </div>
        <modalCrearPromocion ref="modalCrearPromocion" @actualizar="limpiarVistas" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    metaInfo: () =>({ title: 'Promociones'}),
    components: {
        detalle: () => import('./detalle'),
        modalCrearPromocion: () => import('../partials/modalCrearPromocion')
    },
    middleware:['auth'],
    data(){
        return {
            buscar: null,
            menus: [],
            loading: false
        }
    },
    computed:{
        ...mapGetters({
            promociones: 'promociones/promociones/promociones',
            id_promocion: 'promociones/promociones/id_promocion'
        }),
        buscadorPromociones(){
            if(this.buscar !== null && this.buscar !== ''){
                return this.promociones.filter(el => el.nombre.toLowerCase().includes(this.buscar.toLowerCase()))

            }else{
                return this.promociones
            }
        }
    },
    mounted(){
        this.listarPromociones()
    },
    methods: {
        crearPromocion(){
            this.$refs.modalCrearPromocion.toggle()
        },
        limpiarVistas(){
            const detalle = {
                id: null,
                nombre: '',
                imagen: null,
                imagen_show: null,
                tipo: '',
                condiciones: '',
                categorias:1,
                visible: 1,
                promo_categorias:[]
            }
            this.$store.commit('promociones/promociones/set_id_promocion', null)
            this.$store.commit('promociones/promociones/set_detalle', detalle)
            this.$store.commit('promociones/promociones/set_cedis', [])

        },
        async listarPromociones(){
            try {
                this.loading = true
                await this.$store.dispatch('promociones/promociones/action_listar_promociones')
                this.loading = false
            } catch (e){
                this.error_catch(e)
            }
        },
        activarCar(id_promocion){
            this.$store.commit('promociones/promociones/set_id_promocion', id_promocion)
            this.listarDetalle()
        },
        async listarDetalle(){
            try {
                const enviar = {
                    id_promocion: this.id_promocion
                }
                await this.$store.dispatch('promociones/promociones/action_listar_detalle_promocion', enviar)
                // this.loading = false
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="css" scoped>
.menu-prinicipal{
    background: #fff;
}
.btn-agregar-promocion{
    width: 32px;
    background-color: var(--color-general);
    height: 32px;
}
</style>
